<template>
	<div class="a-flex-rfsfs">
		<div class="s-search-label a-flex-rfec a-mt-8" style="width: 82px" v-show="label">
			<span class="a-c-red a-fs-14" v-if="notnull">*</span>
			<div>{{ label }}</div>
		</div>
		<div>
            <el-radio-group v-model="posterType" style="margin-bottom: 20px" v-if="localImg">
                <el-radio :label="1" >网络图片</el-radio>
                <el-radio :label="2" >本地图片</el-radio>
            </el-radio-group>
            <div v-if="posterType == 1">
                <el-upload :show-file-list="false" :action="url" :headers="headers" :data="datas" :on-preview="handlePreview"
                    :on-remove="handleRemove" :before-remove="beforeRemove" :limit="limit" :on-exceed="handleExceed"
                    :on-success="handleSuccess" :on-error="handleError" :file-list="fileList" name="file" :on-change="handleChange"
                    :accept="acceptVali" ref="upload">
                    <!-- <i class="el-icon-plus"></i> -->
                    <el-button style="border: 1px solid #DADDE0;border-radius: 4px;" icon="el-icon-upload2">上传文件</el-button>
                    <slot name="tip">
                        <!-- <div class="el-upload__tip a-mt-8 a-c-999 a-fs-14">
                            支持：{{ acceptStr }}格式
                        </div> -->
                    </slot>
                </el-upload>
                <div class="a-mt-16 a-flex-rfsc a-flex-wrap" v-if="fileList.length">
                    <div class="img-content" v-for="(item, index) in fileList" :key="index">
                        <el-image
                            :src="item.url" 
                            style="width:100px;height:100px;border-radius: 4px;"
                            :preview-src-list='[item.url]'></el-image>
                        <i class="el-icon-error deleteImg" @click="beforeRemove(item, index)"></i>
                    </div>
                </div>
                <div class="el-upload__tip a-c-999 a-fs-14">
                    支持：{{ acceptStr }}格式
                </div>
            </div>
            <div v-else-if="posterType == 2">
                <div class="a-flex-rfsc" style="margin-bottom: 10px" v-for="(item, index) in fileList" :key="index">
                    <el-input v-if="posterType == 2" style="width: 400px;" @input='(e)=>{ item.name = e }' v-model="item.url" placeholder="请输入本地图片地址"></el-input>
                    <el-tooltip class="devList-btn" effect="dark" content="添加" placement="top" v-if="fileList.length < limit">
                        <img src="../../../assets/icon/option-add-circle.png" @click="addLoacalImg" class="a-wh-16 a-mlr-12" />
                    </el-tooltip>
                    <el-tooltip class="devList-btn" effect="dark" content="删除" placement="top" v-if="fileList.length > 1">
                        <img src="../../../assets/icon/option-del-circle.png" @click="delLocalImg(index)" class="a-wh-16 a-mlr-12" />
                    </el-tooltip>
                </div>
            </div>
		</div>
		<le-preview-img v-model="showPreviewImg" :imgList="previewList"></le-preview-img>
	</div>
</template>

<script>
import Utils from '@/utils';
export default {
	props: {
		label: {
			type: String,
		},
		value: {
			required: false,
			default: "",
		},
		notnull: {
			type: Boolean,
			default: false,
			required: false,
		},
		/**
		 * 是否为较长的输入框
		 */
		large: {
			type: Boolean,
			required: false,
			default: false,
		},
		acceptArr: {
			type: Array,
			default: () => [".png", ".jpg", ".jpeg"],
		},
		limit: {
			type: Number,
			default: 1,
		},
		uploadUrl: {
			type: String,
			default: ''
		},
        datas: {
            default: ()=>{
                return {
                    key: "company/upload",
                }
            }
        },
        localImg: {
            default: false,
            type: Boolean
        }
	},
	computed: {
		acceptStr() {
			return this.acceptArr.join("，");
		},
		acceptVali() {
			return this.acceptArr.join(",");
		},
	},
	watch: {
		value: {
            immediate: true,
			handler(val) {
				if (val != "") {
                    console.log(this.iswatch,'1221321');
					if (this.iswatch) {
						if (typeof val == "string") {
							let names = val.split("/");
							let showName = names[names.length - 1];
							if (!this.filesImg[showName]) {
								this.fileList.push({
									name: showName,
									url: this.filesImg[showName] || val,
								});
							}
						}
						this.iswatch = false;
					}
				}
			},
		},
		uploadUrl: {
			immediate: true,
			handler(val) {
				if (val) {
					this.url = this.$Config.axiosBaseUrl + val
				}
			}
		}
	},
	mounted() {
		// this.fileList.push({"name":"aaa.pj"});
		// this.$emit('input', this.fileList)
		// console.log("le-upload-file-img.vue：",this.value);
	},
	data() {
		return {
			url: this.$Config.axiosBaseUrl + this.$Config.apiUrl.uploadFile,
			headers: {
				token: Utils.getToken(),
				appId: this.$Config.appId,
			},
			data: {
				key: "company/upload",
			},
			fileList: [],
			filesImg: {},
			iswatch: true,
			showPreviewImg: false, //是否预览
			previewList: [{}], //预览文件列表
            posterType: 1
		};
	},
	methods: {
		handlerChange(e) {
			this.$emit("input", e);
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			this.showPreviewImg = true;
			this.previewList = [file.url];
		},
		handleExceed(files, fileList) {
			// if (fileList.length > 0) {
			// 	this.$message.warning('该文件已执行过导入操作')
			// 	console.log('handleExceed',fileList)
			// 	// if (fileList[0].response.code == 0) {
			// 	// 	this.$message.warning(
			// 	// 		`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length
			// 	// 		} 个文件，共选择了 ${files.length + fileList.length} 个文件`
			// 	// 	);
			// 	// }
			// }
			this.$message.warning(`当前最多只允许上传${this.limit}个文件`)
		},
		beforeRemove(file, index) {
			let that = this;
			return this.$confirm(`确定移除 ${file.name}？`, {
				callback: (action) => {
					if (action == "confirm") {
						that.$emit("input", "");
						that.fileList.splice(index, 1);
					}
				},
			});
		},
        delLocalImg (index) {
            this.$emit("input", "");
            this.fileList.splice(index, 1);
        },
        addLoacalImg () {
            this.fileList.push({
                name: '',
                url: ''
            })
            this.$emit("input", this.fileList);
        },
		handleChange(file, fileList) {
			if (file.response) {
				if (file.response.code == 0) {

					this.iswatch = false;
					this.filesImg[file.name] = file.url || "";
					// debugger
					this.fileList.push({
						name: file.name,
						url: file.response.data
					})
					this.$emit("input", file.response.data);
				} else {
					this.fileList = []
					this.filesImg = {}
				}

			}
		},
		handleSuccess(response, file, fileList) {
			let that = this
			if (response.code == 0) {
				this.$emit('handleSuccess',response.data)
			} else {
				this.fileList = []
				this.filesImg = {}
				this.$message.error(response.msg)
			}
		},
		handleError () {
			this.$refs['upload'].clearFiles()
		}
	},
};
</script>
<style lang="scss" scoped>
.el-upload__tip {
	white-space: nowrap;
}

.img-content {
	width: 100px;
	height: 100px;
	position: relative;
	margin-right: 14px;
    margin-bottom: 14px;
	.deleteImg {
		font-size: 18px;
		position: absolute;
		top: -8px;
		right: -8px;
		color: #606366;
		background: #fff;
		border-radius: 50%
	}
}
</style>
