<template>

    <div class="navbar">
        <div class="a-flex-rsbc" style="height: 60px">
            <div class="a-ml-25 a-flex-rcc">
                <span class="a-fs-24 a-ff-zihun" style="color: #414345"
                    >{{config.brand}}管理系统</span
                >
                <hamburger></hamburger>
                <!-- <img src="../../../assets/icon/nav-menu.png" class="a-btn-img a-wh-16 a-ml-30" /> -->
            </div>
            <div class="a-flex-rcc a-c-333 a-plr-40">
                <div class="a-flex-rfsc" v-if="userInfo.dataPermission != 77">
                    <img src="../../../assets/icon/nav-home.png" class="a-btn-img a-wh-16" />
                    <span class="a-fs-14 a-ml-08 font-point" @click="toBusinessCenter">商户中心</span>
                </div>
                
                <!-- <div class="a-flex-rfsc" v-if="userInfo.dataPermission == 99" @click="toDataCenter">
                    <img src="../../../assets/icon/nav-data-icon.png" class="a-btn-img a-ml-40" style="width:20px;height:20px"/>
                    <span class="a-fs-14 a-ml-08 font-point">数据中心</span>
                </div> -->
                <!-- <el-dropdown trigger="hover" @command="handleCommandData">
                    
                    <el-dropdown-menu slot="dropdown" class="dropdownbox">
                        <el-dropdown-item
                            
                            command="dataCenter"
                            >数据中心</el-dropdown-item>
                        <el-dropdown-item
                            command="dataDevOps"
                            >运维看板</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->

                <el-tooltip :value="showExportTips" effect="dark" manual content="添加导出任务成功，请前往下载！" placement="bottom">
                    <div class="a-flex-rcc">
                        <img src="../../../assets/icon/export-list-btn.png" class="a-btn-img a-wh-16 a-ml-40" />
                        <span class="a-fs-14 a-ml-08 font-point" @click="toExportList">导出列表</span>
                    </div>
                </el-tooltip>

                <div class="a-flex-rfsc" v-if="userInfo.dataPermission != 77">
                    <img src="../../../assets/icon/nav-message.png" class="a-btn-img a-wh-16 a-ml-40" />
                    <span class="a-fs-14 a-ml-08 font-point" @click="toNews">消息</span>
                    <el-badge
                        :value="newsnum.totalNum"
                        :max="99"
                        class="itembadge a-ff-hn"
                        v-if="newsnum.totalNum"
                    >
                    </el-badge>
                </div>
                
                <img
                    :src="avatarUrl"
                    class="a-btn-img a-wh-40 a-ml-40 a-br-25"
                    v-if="avatarUrl"
                />
                <img
                    src="../../../assets/icon/avatarUrlimg.png"
                    alt=""
                    v-else
                    class="avatarUrlimg"
                />
                <div class="a-flex-cfsfs">
                    <span class="a-fs-14 a-ml-08">{{ nickName }}</span>
                    <span class="a-fs-14 a-ml-08">{{ userPhone }}</span>
                </div>
                <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        <img
                            src="../../../assets/icon/nav-down.png"
                            class="a-btn-img a-wh-16 a-ml-08"
                        />
                    </span>
                    <el-dropdown-menu slot="dropdown" class="dropdownbox">
                        <el-dropdown-item
                            icon="el-icon-my-tuichudenglu"
                            command="goout"
                            >退出</el-dropdown-item
                        >
                        <el-dropdown-item
                            icon="el-icon-my-xiugaimima"
                            command="editpassword"
                            >修改密码</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>
<script>

import { mapGetters, mapState, mapActions } from "vuex";
import util from "../../../utils/index";
import Hamburger from "@/components/Hamburger/index";
import config from '../../../config/index.js'
export default {
    data() {
        return {
            nickName: "",
            userPhone: '',
            avatarUrl: "",
			config:config
        };
    },
    components: {
        Hamburger,
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.user,
            newsnum: (state) => state.newsnum.newsnum,
            showExportTips: (state) => state.exportFile.showExportTips,
        }),
    },
    mounted() {
        this.nickName = this.userInfo.nickName;
        this.avatarUrl = this.userInfo.avatarUrl;
        this.userPhone = this.userInfo.mobile;
    },
    methods: {
        ...mapActions({
            gooutLogin: "user/logout",
            setMenu: "menu/setMenu"
        }),
        toBusinessCenter() {
            this.$router.push({
                path: "/businessCenter/business-center",
            });
        },
        handleCommand(command) {
            if (command == "goout") {
                this.gooutLogin().then(() => {
                    setTimeout(() => {
                        this.$router.replace({
                            path: "/login",
                        });
                        this.setMenu([])
                    }, 1000);
                });
            }else if(command == "editpassword") {
                this.$router.push({ 
                    path: '/userMGT/userMGT-password-edit',
                });
            }
        },
        toNews() {
            this.$router.push({
                path: "/news/news-list",
            });
        },
        toExportList () {
            this.$router.push({
                path: "/export/export-list",
            });
        },
        toggleClick() {
            this.$store.commit("hamburger/triggerSidebarOpened");
        },
        handleCommandData (command) {
            if (command == "dataCenter") {
                this.toDataCenter()
            }else if(command == "dataDevOps") {
                this.$router.push({ 
                    path: '/dataPanel/data-inspection',
                });
            }
        },
        toDataCenter () {
            // this.$router.push({
            //     path: "/data",
            // });
            const routeUrl = this.$router.resolve({
                path: "/data"
            });
            window.open(routeUrl.href, '_blank')
        }
    },
};
</script>
<style lang="scss" scoped>
.navbar {
    position: fixed;
    width: 100%;
    height: 64px;

    top: 0;
    left: 0;
    background: #fff;
    right: 0;
    z-index: 10;
}

.dropdownbox {
    // width: 112px;
    // height: 80px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0;

    /deep/ .el-dropdown-menu__item {
        padding: 6px 16px;
    }

    /deep/ .el-dropdown-menu__item:hover {
        background: #f2f8ff;
    }
}

/deep/ .el-dropdown-menu {
    padding: 0;
}

.ptb-3 {
    padding-top: 3px;
    padding-bottom: 3px;
}

.itembadge /deep/ .el-badge__content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #ff3b30;
    margin-left: 8px;
    top: 0;
}

.avatarUrlimg {
    height: 40px;
    width: 40px;
    margin-left: 40px;
}
</style>
